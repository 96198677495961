define('trecki-planner/controllers/helsinki/sponsoren', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    _slides: null,
    wrap: true,
    init() {
      this._super(...arguments);
      this.set('_slides', [{ alt: 'First slide', src: 'assets/images/vidius.png' }, { alt: 'Second slide', src: 'assets/images/Ufonds.jpg' }, { alt: 'Thirth slide', src: 'assets/images/cki.png' }, { alt: 'Fourth slide', src: 'assets/images/trecki2.png' }]);
    },
    integerIndex: Ember.computed('index', function () {
      return parseInt(this.get('index'), 10);
    }),
    integerInterval: Ember.computed('interval', function () {
      return parseInt(this.get('interval'), 10);
    }),
    index: 0,
    interval: 5000,
    keyboard: true,
    slides: Ember.computed('_slides', 'numberOfSlides', function () {
      return this.get('_slides').slice(0, this.get('numberOfSlides'));
    }),
    ltr: true,
    numberOfSlides: 5,
    pauseOnMouseEnter: true,
    showControls: true,
    showIndicators: true
  });
});