define('trecki-planner/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    actions: {
      back: function () {
        history.back();
      },

      openLink: function (url) {
        window.open(url, '_system');
      }
    }
  });
});