define('trecki-planner/controllers/riga', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    options: ['Go to Helsinki', 'Go to Tallinn', 'Intro', 'Programma', 'Feitjes', 'Uitgaan', 'Koers', 'Snelcursus', 'Sponsoren', 'Contact'],
    rightSideBarOpen: false,

    actions: {
      toggle(propName) {
        this.toggleProperty(propName);
      },

      openSomething(item) {
        if (item == 'Go to Helsinki') {
          this.send('helsinki');
        }
        if (item == 'Go to Tallinn') {
          this.send('tallinn');
        } else {
          this.send(`${item}`);
        }
      },

      helsinki() {
        this.transitionToRoute('helsinki.intro');
      },

      tallinn() {
        this.transitionToRoute('tallinn.intro');
      },

      Intro() {
        this.transitionToRoute('riga.intro');
      },

      Programma() {
        this.transitionToRoute('riga.programma');
      },

      Feitjes() {
        this.transitionToRoute('riga.feitjes');
      },

      Uitgaan() {
        this.transitionToRoute('riga.uitgaan');
      },

      Koers() {
        this.transitionToRoute('riga.valuta');
      },

      Snelcursus() {
        this.transitionToRoute('riga.snelcursus');
      },

      Sponsoren() {
        this.transitionToRoute('riga.sponsoren');
      },

      Contact() {
        this.transitionToRoute('riga.contact');
      }
    }
  });
});