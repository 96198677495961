define('trecki-planner/initializers/in-app-livereload', ['exports', 'trecki-planner/config/environment', 'ember-cli-cordova/initializers/in-app-livereload'], function (exports, _environment, _inAppLivereload) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = undefined;
  /* globals cordova */

  var inAppReload = _inAppLivereload.default.initialize;

  var initialize = exports.initialize = function (app) {
    if (typeof cordova === 'undefined' || _environment.default.environment !== 'development' || _environment.default.cordova && (!_environment.default.cordova.liveReload || !_environment.default.cordova.liveReload.enabled)) {
      return;
    }

    return inAppReload(app, _environment.default);
  };

  exports.default = {
    name: 'cordova:in-app-livereload',
    initialize: initialize
  };
});