define('trecki-planner/router', ['exports', 'trecki-planner/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('helsinki', function () {
      this.route('intro');
      this.route('feitjes');
      this.route('uitgaan');
      this.route('valuta');
      this.route('contact');
      this.route('sponsoren');
      this.route('programma');
      this.route('snelcursus');
    });
    this.route('tallinn', function () {
      this.route('intro');
      this.route('feitjes');
      this.route('uitgaan');
      this.route('valuta');
      this.route('contact');
      this.route('sponsoren');
      this.route('programma');
      this.route('snelcursus');
    });
    this.route('riga', function () {
      this.route('intro');
      this.route('feitjes');
      this.route('uitgaan');
      this.route('valuta');
      this.route('contact');
      this.route('sponsoren');
      this.route('programma');
      this.route('snelcursus');
    });
  });

  exports.default = Router;
});