define('trecki-planner/controllers/tallinn', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    options: ['Go to Helsinki', 'Go to Riga', 'Intro', 'Programma', 'Feitjes', 'Uitgaan', 'Koers', 'Snelcursus', 'Sponsoren', 'Contact'],
    rightSideBarOpen: false,

    actions: {
      toggle(propName) {
        this.toggleProperty(propName);
      },

      openSomething(item) {
        if (item == 'Go to Helsinki') {
          this.send('helsinki');
        }
        if (item == 'Go to Riga') {
          this.send('riga');
        } else {
          this.send(`${item}`);
        }
      },

      helsinki() {
        this.transitionToRoute('helsinki.intro');
      },

      riga() {
        this.transitionToRoute('riga.intro');
      },

      Intro() {
        this.transitionToRoute('tallinn.intro');
      },

      Programma() {
        this.transitionToRoute('tallinn.programma');
      },

      Feitjes() {
        this.transitionToRoute('tallinn.feitjes');
      },

      Uitgaan() {
        this.transitionToRoute('tallinn.uitgaan');
      },

      Koers() {
        this.transitionToRoute('tallinn.valuta');
      },

      Snelcursus() {
        this.transitionToRoute('tallinn.snelcursus');
      },

      Sponsoren() {
        this.transitionToRoute('tallinn.sponsoren');
      },

      Contact() {
        this.transitionToRoute('tallinn.contact');
      }
    }
  });
});